<template>
  <!-- nx-arrow-head-circle -->
  <ellipse
    :cx="props.centerX"
    :cy="props.centerY"
    :rx="props.radius"
    :ry="props.radius"
    :stroke-width="props.strokeWidth"
    fill="none"
  />
</template>

<script setup lang="ts">
interface IProps {
  /**
   * Center point of the circle on the X axis
   */
  centerX: number
  /**
   * Center point of the circle on the Y axis
   */
  centerY: number
  /**
   * Radius of the circle in px
   */
  radius: number
  /**
   * Line width of the circle border
   */
  strokeWidth?: number
}

const props = withDefaults(defineProps<IProps>(), {
  strokeWidth: 2,
})
</script>
