export const splitFilenameToPathAndFilename = (
  filenameWithPath,
  isPrivateFile
) => {
  const filenameWithPathSplit = filenameWithPath.split('/')

  const filename = filenameWithPathSplit.pop()
  const bucketPath = `${
    isPrivateFile ? '' : 'public/'
  }${filenameWithPathSplit.join('/')}`

  return { filename, bucketPath }
}
