<template lang="pug">
transition(@leave='leave')
  splashscreen(v-if="isLoading")
template(v-if="!isLoading")
  .m100-progress(v-if="progress")
    .progress-bar(:style="{ width: (progress * 100) + '%' }")
  nav-top
  section(:class="$root.screen.theme ? 'pdf' : 'page'")
    router-view(v-if="(!$root.screen.datasets || $root.x)")
  nav-bottom
  toast(:messages="messages")
</template>
<script>
export default {
  inject: ['progress'],
  methods: {
    leave(el, done) {
      el.style.opacity = 0
      setTimeout(() => done(), 500)
    },
  },
  computed: {
    messages() {
      return this.$root.messages
    },
    isLoading() {
      return false
    },
  },
}
</script>
