<template>
  <!-- story-accordeon -->
  <!-- <div
    v-if="props.showBorder && props.showHeader"
    class="z-10 h-2 w-full bg-white"
    :class="[props.stickyTop ? 'vsg-sticky-top' : '']"
    :style="{ zIndex: 10 }"
  ></div> -->
  <nx-tree-node
    :tree-styles="{
      class_node: props.showBorder ? '' : '',
      style_node: { borderWidth: props.showBorder ? '1px' : 0, marginTop: '4px', marginBottom: '4px' },
      class_node_header: [
        'px-2 !w-full bg-white',
        props.stickyTop ? 'vsg-sticky-top' : '',
        props.showBorder ? 'border border-solid border-gray-300 rounded' : '',
      ],
      class_node_header__expanded: 'rounded-b-none !border-b',
      class_content_root: props.showBorder ? 'border-solid border-gray-300 !border-t-0 rounded-b' : '',
      class_content_root__expanded: '!border',
    }"
    :is-sticky="false"
  >
    <template v-if="props.showHeader" #header="{ isExpanded, toggleExpanded }">
      <div class="flex h-full w-full cursor-pointer items-center" @click="toggleExpanded()">
        <slot name="superheader">
          <div class="grow flex-col px-1.5 py-1.5">
            <slot name="header"></slot>
          </div>
          <nx-button class="mr-1.5" size="lg">
            <template #icon-center>
              <svg
                :class="[isExpanded ? '' : 'rotate-[-90deg]']"
                role="img"
                width="32px"
                height="32px"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 24 24"
                class="iconify iconify--ic transition-transform"
              >
                <path
                  fill="currentColor"
                  d="M8.12 9.29L12 13.17l3.88-3.88a.996.996 0 1 1 1.41 1.41l-4.59 4.59a.996.996 0 0 1-1.41 0L6.7 10.7a.996.996 0 0 1 0-1.41c.39-.38 1.03-.39 1.42 0z"
                ></path>
              </svg>
            </template>
          </nx-button>
        </slot>
      </div>
    </template>
    <template #content="{ isExpanded }">
      <div
        class="w-full flex-col"
        :class="[props.showHeader ? 'px-[14px]' : 'px-2', isExpanded ? '' : 'h-0 overflow-y-hidden']"
      >
        <slot name="content"></slot>
      </div>
    </template>
  </nx-tree-node>
</template>

<script setup lang="ts">
interface IProps {
  /**
   * Boolean that indicates if accordeon should be surrounded by border or not
   */
  showBorder?: boolean
  showHeader?: boolean
  stickyTop?: boolean
}

const props = withDefaults(defineProps<IProps>(), {
  showBorder: true,
  showHeader: true,
})
</script>
