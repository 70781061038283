<template lang="pug">
.lang
  label(v-if="!hideLabel")  {{ t.lang }}
  select#lang.dark(:value='$root.lang', @change='$root.lang = $event.target.value')
    option(:value='lang', v-for='lang in $root.langs')  {{ lang.toUpperCase() }}
</template>

<script>
export default {
  props: {
    hideLabel: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
