import { computed, onMounted, onUnmounted, ref } from 'vue'
import { commandrSDK } from '../../../features/commandr'
export function useUsers() {
  const loaded = ref(false)
  const users = ref([])
  const emails = computed(() => users.value.map(u => u.email).sort())
  const update = snap => {
    users.value = (snap || []).filter()
    loaded.value = true
  }
  onMounted(() => {
    commandrSDK.on('/data/users', 'value', update)
    loaded.value = false
  })
  onUnmounted(() => {
    commandrSDK.off('/data/users', 'value')
  })
  return {
    users,
    emails,
    loaded,
  }
}
